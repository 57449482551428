import styles from '@/styles/swiper.module.scss'
import SwiperSelf from '@/components/SwiperSelf'
import { SwiperSlide } from 'swiper/react'
import Link from 'next/link'
import { useState, useEffect } from "react"
import { abTestCalculate } from "@/utils/ABtest"
import { getCookie } from '@/utils/cookie'

interface BannerItem {
  imgUrl: string;
  title: string;
  link: string;
}

interface props {
  bannerList: BannerItem[];
}

export default function Swiper({ bannerList }: props) {
  function handlePageJump() {
    // ReactGA.event({
    //   category: 'New Home Page',
    //   action: 'Click',
    //   label: `Slider - ${banner.title}`,
    // });
    // window.location.href = "https://" + window.location.host + banner.link
  }

  /**
   * UVP 的 AB test
   */
   const [abTestVersion, setAbTestVersion] = useState('A');

  //  useEffect(() => {
  //    // 先看看cookie，当前是A还是B，cookie没有的话，从abTestCalculate拿
  //    if (getCookie('X-Service-Group-AB')) {
  //      setAbTestVersion(getCookie('X-Service-Group-AB') || 'A')
  //    } else {
  //      abTestCalculate("2024-02-05_ABTest").then((res: unknown) => {
  //        console.log(`进去页面开始算流量、流量落在${res as string}`)
  //        setAbTestVersion(res as string || 'A');
  //      })
  //    }
  //  }, []);


  return (
    <div className={styles['swiper-box']}>
      <SwiperSelf slidesPerView={1} pagination={true} loop={true} autoplay={true}>
        {
          bannerList.map((banner) =>
            <SwiperSlide key={banner.link}>
              <Link
                href={banner.link}
                legacyBehavior
              >
                <a 
                  target="_blank"
                  title={banner.title}
                  rel="noopener noreferrer"
                  style={{ display: 'block' }}
                >
                  <div
                    className={styles['banner-item-bg']}
                    style={{ height: `${ abTestVersion == 'A' ? '400px' : '400px'}`, backgroundImage: `url(${banner.imgUrl})` }}
                    onClick={() => handlePageJump()}
                  ></div>
                </a>
              </Link>
            </SwiperSlide>
          )
        }
      </SwiperSelf>
    </div>
  )
}
